<template>
  <div>
    <v-card>
      <v-card-title>
        <v-btn color="indigo darken-4" dark class="mb-2 mx-1" @click="nuevo()">
          Nuevo
        </v-btn>
        <v-btn color="teal darken-4" dark class="mb-2 mx-1" @click="comparar()">
          Comparar
        </v-btn>

        <v-divider class="mx-4" inset vertical></v-divider>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        dense
        v-model="selected"
        :headers="headers"
        :items="scenarios"
        :search="search"
        :footer-props="{
          'items-per-page-text': '',
        }"
        locale="es-CL"
        show-select
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon color="info" @click="downCarga(item)" v-on="on">
                mdi-file-download-outline
              </v-icon>
            </template>
            <span>Carga</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon color="warning" @click="downObs(item)" v-on="on">
                mdi-file-download-outline
              </v-icon>
            </template>
            <span>Observaciones</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon color="purple darken-2" @click="downGoal(item)" v-on="on">
                mdi-file-download-outline
              </v-icon>
            </template>
            <span>Goal</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon color="success" @click="downRes(item)" v-on="on">
                mdi-file-download-outline
              </v-icon>
            </template>
            <span>Resultado</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon color="pink darken-2" @click="downKpi(item)" v-on="on">
                mdi-file-download-outline
              </v-icon>
            </template>
            <span>Indicadores</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar v-model="snackbar">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          CERRAR
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialog">
      <v-card>
        <v-simple-table dense>
          <template v-slot:default>
            <thead class="indigo darken-4">
              <tr>
                <th class="text-left white--text">ID</th>
                <th class="text-left white--text">VIAJES COMERCIALES</th>
                <th class="text-left white--text">KM COMERCIAL</th>
                <th class="text-left white--text">VIAJES NO COMERCIALES</th>
                <th class="text-left white--text">KM NO COMERCIAL</th>
                <th class="text-left white--text">BUSES</th>
                <th class="text-left white--text">VAR % BUSES</th>
                <th class="text-left white--text">DESCRIPCIÓN</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in selected" :key="item.uuid">
                <td v-if="index == 0">{{ item.uuid }} (BASE)</td>
                <td v-if="index != 0">{{ item.uuid }}</td>
                <td>{{ item.comercial_trips || 7182 }}</td>
                <td>{{ item.comercial_km || 166448 }}</td>
                <td>{{ item.nocomercial_trips || 5498 }}</td>
                <td>{{ item.nocomercial_km || 58877 }}</td>
                <td>{{ item.buses || 742 }}</td>
                <td>
                  {{
                    (
                      (((item.buses || 742) - (selected[0].buses || 742)) /
                        (selected[0].buses || 742)) *
                      100
                    ).toFixed(2)
                  }}%
                </td>
                <td>{{ item.description }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-card-actions class="justify-end">
          <v-btn text @click="dialog = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { firebase } from "@/firebase";
const storageRef = firebase.storage().ref();
export default {
  computed: {
    scenarios: function () {
      return this.$store.state.scenarios;
    },
  },
  data() {
    return {
      selected: [],
      snackbar: false,
      snackbar_text: "",
      dialog: false,
      search: "",
      headers: [
        { text: "ID", value: "uuid" },
        { text: "USUARIO", value: "user_name" },
        { text: "CREACIÓN", value: "creation_datetime" },
        { text: "DESC", value: "description" },
        { text: "ESTADO", value: "state_desc" },
        { text: "ACCIONES", value: "actions", sortable: false },
      ],
    };
  },
  watch: {
    search: function (val) {
      this.search = val.toUpperCase();
    },
  },
  methods: {
    nuevo() {
      this.$router.push({
        name: "scenarios_add",
      });
    },
    comparar() {
      if (this.selected.length < 2) {
        this.snackbar = true;
        this.snackbar_text = "Minimo 2 escenarios para comparar";
      } else {
        //console.log(this.selected);
        this.dialog = true;
      }
    },
    //0 Ingresado, 1 Procesando, 2 Obs, 3 Goal, 4 Resultado
    async downCarga(item) {
      window.location.href = await storageRef
        .child(`validar_gis/carga_${item.uuid}.xlsx`)
        .getDownloadURL();
    },
    async downObs(item) {
      if (item.state !== 2) {
        this.snackbar = true;
        this.snackbar_text = "Sin observaciones";
      } else
        window.location.href = await storageRef
          .child(`validar_gis/obs_${item.uuid}.txt`)
          .getDownloadURL();
    },
    async downGoal(item) {
      if (
        item.state !== 3 &&
        item.state !== 4 &&
        item.state !== 5 &&
        item.state !== 6
      ) {
        this.snackbar = true;
        this.snackbar_text = "Sin carga Goal";
      } else
        window.location.href = await storageRef
          .child(`validar_gis/goal_${item.uuid}.zip`)
          .getDownloadURL();
    },
    async downRes(item) {
      if (item.state !== 6) {
        this.snackbar = true;
        this.snackbar_text = "Sin resultado";
      } else
        window.location.href = await storageRef
          .child(`validar_gis/resultado_${item.uuid}.xlsm`)
          .getDownloadURL();
    },
    async downKpi(item) {
      if (item.state !== 6) {
        this.snackbar = true;
        this.snackbar_text = "Sin resultado";
      } else {
        const kpis = [
          "NCDE2cAf8xPqHF13wjDK",
          "Ra96X6Uj55MN1PWvdoev",
          "GFhVyiLf5JydpyJ1nfcI",
          "VWxtkusCkZqcNoM2T3NH",
          "qnoorb1JKFy04ZJAVpKC",
        ];
        if (kpis.includes(item.uuid))
          window.open(
            await storageRef
              .child(`validar_gis/kpi_${item.uuid}.pdf`)
              .getDownloadURL(),
            "_blank"
          );
        else
          window.open(
            await storageRef.child(`validar_gis/kpi.pdf`).getDownloadURL(),
            "_blank"
          );
      }
    },
  },
};
</script>
